import React from 'react';
import { useDrag } from 'react-dnd';
import { Task } from './Task';

interface DealProps {
  deal: DealType;
  columnId: string;
  onEdit: (deal: DealType) => void;
}

export interface DealType {
  id: string;
  name: string;  // This stays as 'name' in the interface
  email: string;
  phone: string;
  notes: string;
  profileLink: string;
  tasks: Task[];
  status: string;
  createdAt: string; // Add this line
}

const Deal: React.FC<DealProps> = ({ deal, columnId, onEdit }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'DEAL',
    item: { id: deal.id, columnId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const getTaskIndicator = () => {
    if (deal.tasks.length === 0) return 'yellow'; // No tasks yet

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const hasRedTask = deal.tasks.some(task => {
      if (!task.dueDate) return false;
      const dueDate = new Date(task.dueDate);
      dueDate.setHours(0, 0, 0, 0);
      return task.status !== 'Done' && dueDate < today;
    });

    const hasGreenTask = deal.tasks.some(task => {
      if (!task.dueDate) return false;
      const dueDate = new Date(task.dueDate);
      dueDate.setHours(0, 0, 0, 0);
      return task.status !== 'Done' && dueDate.getTime() === today.getTime();
    });

    const allTasksDone = deal.tasks.every(task => task.status === 'Done');

    if (hasRedTask) return 'red';
    if (hasGreenTask) return 'green';
    if (allTasksDone) return 'yellow';
    return null;
  };

  const indicator = getTaskIndicator();

  return (
    <div
      ref={drag}
      onClick={() => onEdit(deal)}
      className={`bg-white p-3 mb-2 rounded shadow ${
        isDragging ? 'opacity-50' : ''
      } cursor-pointer hover:bg-gray-100 relative`}
    >
      <h4 className="font-semibold">{deal.name}</h4>
      <p className="text-sm text-gray-600">{deal.email}</p>
      <p className="text-sm text-gray-600">{deal.phone}</p>
      {indicator && (
        <div 
          className={`absolute top-0 right-0 w-3 h-3 rounded-full m-1 ${
            indicator === 'red' ? 'bg-red-500' : 
            indicator === 'green' ? 'bg-green-500' : 
            'bg-yellow-500'
          }`}
        ></div>
      )}
    </div>
  );
}

export default Deal;
