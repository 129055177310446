import React from 'react';

const IntegrationPage: React.FC = () => {
  const integrations = [
    { name: 'Jobber', logo: 'https://mma.prnewswire.com/media/1996598/Jobber_Jobber_Raises__100_Million_Growth_Round.jpg' },
    { name: 'House Call Pro', logo: 'https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_1f3e71afa76e797defe56c8b1502f99c/housecall-pro.jpg' },
    { name: 'Service Titan', logo: 'https://images.saasworthy.com/servicetitan_1769_logo_1576576441_wjh9h.png' },
  ];

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold mb-4 p-6">Integrations</h2>
      <div className="flex-grow overflow-y-auto px-6 pb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {integrations.map((integration) => (
            <div key={integration.name} className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
              <img src={integration.logo} alt={`${integration.name} logo`} className="w-32 h-32 object-contain mb-4" />
              <h3 className="text-xl font-semibold mb-2">{integration.name}</h3>
              <p className="text-gray-600 text-center">Coming Soon</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;
