import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CurrencyDollarIcon, ChartBarIcon, CogIcon } from '@heroicons/react/24/outline';

interface SidebarProps {
  activeView: 'deals' | 'analytics' | 'integrations';
}

const Sidebar: React.FC<SidebarProps> = ({ activeView }) => {
  const location = useLocation();

  return (
    <div className="bg-gray-900 text-gray-100 w-45 py-7 px-2 h-screen flex flex-col">
      <div className="flex items-center space-x-2 px-4 mb-6">
        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
        </svg>
        <span className="text-2xl font-extrabold">SalesUp</span>
      </div>
      <nav className="flex-1">
        <Link
          to="/deals"
          className={`flex items-center space-x-2 py-3 px-4 rounded transition duration-200 w-full ${
            location.pathname === '/deals' ? 'bg-blue-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white'
          }`}
        >
          <CurrencyDollarIcon className="w-6 h-6" />
          <span className="text-lg">Deals</span>
        </Link>
        <Link
          to="/analytics"
          className={`flex items-center space-x-2 py-3 px-4 rounded transition duration-200 w-full ${
            location.pathname === '/analytics' ? 'bg-blue-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white'
          }`}
        >
          <ChartBarIcon className="w-6 h-6" />
          <span className="text-lg">Analytics</span>
        </Link>
        <Link
          to="/integrations"
          className={`flex items-center space-x-2 py-3 px-4 rounded transition duration-200 w-full ${
            location.pathname === '/integrations' ? 'bg-blue-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white'
          }`}
        >
          <CogIcon className="w-6 h-6" />
          <span className="text-lg">Integrations</span>
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
