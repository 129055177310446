import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { supabase } from '../supabase';

interface Column {
  id: string;
  title: string;
  deals: any[];
  order: number;
}

interface EditColumnsModalProps {
  isOpen: boolean;
  onClose: () => void;
  columns: Column[];
  onUpdateColumns: (columns: Column[]) => void;
}

const EditColumnsModal: React.FC<EditColumnsModalProps> = ({ isOpen, onClose, columns, onUpdateColumns }) => {
  const [editedColumns, setEditedColumns] = useState<Column[]>(columns);

  useEffect(() => {
    setEditedColumns(columns);
  }, [columns]);

  const handleTitleChange = (id: string, newTitle: string) => {
    setEditedColumns(editedColumns.map(col => col.id === id ? { ...col, title: newTitle } : col));
  };

  const handleAddColumn = () => {
    const newOrder = editedColumns.length > 0 ? Math.max(...editedColumns.map(col => col.order)) + 1 : 0;
    setEditedColumns([...editedColumns, { id: Date.now().toString(), title: 'New Column', deals: [], order: newOrder }]);
  };

  const handleDeleteColumn = (id: string) => {
    const columnToDelete = editedColumns.find(col => col.id === id);
    if (columnToDelete && columnToDelete.deals.length > 0) {
      alert('Cannot delete column with deals. Please relocate deals first.');
      return;
    }
    setEditedColumns(editedColumns.filter(col => col.id !== id));
  };

  const handleSave = async () => {
    const updatedColumns = editedColumns.map((editedColumn, index) => {
      const originalColumn = columns.find(col => col.id === editedColumn.id);
      return {
        ...editedColumn,
        deals: originalColumn ? originalColumn.deals : [],
        order: index, // Update order based on current position
      };
    });

    // Update columns in the database
    for (const column of updatedColumns) {
      const originalColumn = columns.find(col => col.id === column.id);
      if (originalColumn) {
        // Update existing column
        await supabase.from('columns').update({ title: column.title, order: column.order }).eq('id', column.id);

        // Update deals' status if the column title has changed
        if (originalColumn.title !== column.title) {
          await supabase.from('deals').update({ status: column.title }).eq('status', originalColumn.title);
        }
      } else {
        // Insert new column
        const { data, error } = await supabase.from('columns').insert([{ title: column.title, order: column.order }]).select();
        if (error) {
          console.error('Error creating column:', error);
        } else if (data && data.length > 0) {
          column.id = data[0].id; // Update the local column ID with the one from the database
        }
      }
    }

    // Delete removed columns from the database
    for (const column of columns) {
      if (!updatedColumns.find(col => col.id === column.id)) {
        await supabase.from('columns').delete().eq('id', column.id);
      }
    }

    onUpdateColumns(updatedColumns);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Edit Columns</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        {editedColumns.map(column => (
          <div key={column.id} className="flex items-center mb-2">
            <input
              type="text"
              value={column.title}
              onChange={(e) => handleTitleChange(column.id, e.target.value)}
              className="flex-grow p-2 border rounded mr-2"
            />
            <button
              onClick={() => handleDeleteColumn(column.id)}
              className="text-red-500 hover:text-red-700"
            >
              Delete
            </button>
          </div>
        ))}
        <button
          onClick={handleAddColumn}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add Column
        </button>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditColumnsModal;


