import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Column from './Column';
import EditColumnsModal from './EditColumnsModal';
import DealModal from './DealModal';
import { DealType } from './Deal';
import { Task } from './Task';

interface ColumnType {
  id: string;
  title: string;
  deals: DealType[];
  order: number;
}

interface KanbanBoardProps {
  deals: DealType[];
  columns: ColumnType[];
  onAddDeal: (deal: Omit<DealType, 'id' | 'createdAt'>) => void;
  onUpdateDeal: (id: string, updatedDeal: Partial<DealType>) => void;
  onDeleteDeal: (id: string) => void;
  onUpdateColumns: (columns: ColumnType[]) => void;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({ deals, columns, onAddDeal, onUpdateDeal, onDeleteDeal, onUpdateColumns }) => {
  const [localColumns, setLocalColumns] = useState<ColumnType[]>(columns);
  const [isEditColumnsModalOpen, setIsEditColumnsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<DealType[]>([]);
  const [selectedDeal, setSelectedDeal] = useState<DealType | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocalColumns(columns.map(column => ({
      ...column,
      deals: deals.filter(deal => deal.status === column.title) || []
    })));
  }, [deals, columns]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0;
    }
  }, [localColumns]);

  const addDeal = (columnId: string, deal: Omit<DealType, 'id' | 'status' | 'createdAt'>) => {
    const column = localColumns.find(col => col.id === columnId);
    if (column) {
      onAddDeal({ ...deal, status: column.title });
    }
  };

  const moveDeal = (dealId: string, fromColumnId: string, toColumnId: string) => {
    const updatedColumns = localColumns.map(column => {
      if (column.id === fromColumnId) {
        return {
          ...column,
          deals: column.deals.filter(deal => deal.id !== dealId)
        };
      }
      if (column.id === toColumnId) {
        const movedDeal = deals.find(deal => deal.id === dealId);
        if (movedDeal) {
          return {
            ...column,
            deals: [...column.deals, { ...movedDeal, status: column.title }]
          };
        }
      }
      return column;
    });

    setLocalColumns(updatedColumns);
    onUpdateColumns(updatedColumns);
    onUpdateDeal(dealId, { status: updatedColumns.find(col => col.id === toColumnId)?.title });
  };

  const updateColumnDeals = (columnId: string, updatedColumn: { deals: DealType[] }) => {
    setLocalColumns(localColumns.map(column =>
      column.id === columnId ? { ...column, ...updatedColumn } : column
    ));
    updatedColumn.deals.forEach(deal => {
      onUpdateDeal(deal.id, deal);
    });
  };

  const updateColumns = (newColumns: ColumnType[]) => {
    setLocalColumns(newColumns.map(newColumn => {
      const existingColumn = localColumns.find(col => col.id === newColumn.id);
      return {
        ...newColumn,
        deals: existingColumn ? existingColumn.deals : [],
      };
    }));
  };

  const addTask = (dealId: string, task: Omit<Task, 'id'>) => {
    // Implement this function
  };

  const updateTask = (dealId: string, taskId: string, updatedTask: Partial<Task>) => {
    // Implement this function
  };

  const deleteTask = (dealId: string, taskId: string) => {
    // Implement this function
  };

  const filteredDeals = useMemo(() => {
    const trimmedQuery = searchQuery.trim();
    if (!trimmedQuery) return [];

    const normalizedQuery = trimmedQuery.toLowerCase();
    const numericQuery = normalizedQuery.replace(/\D/g, '');

    return deals.filter((deal) => {
      const nameMatch = deal.name.toLowerCase().includes(normalizedQuery);
      const emailMatch = deal.email.toLowerCase().includes(normalizedQuery);
      
      const normalizedPhone = deal.phone.replace(/\D/g, '');
      const phoneMatch = numericQuery.length > 0 && normalizedPhone.includes(numericQuery);

      return nameMatch || emailMatch || phoneMatch;
    });
  }, [deals, searchQuery]);

  useEffect(() => {
    setSearchResults(filteredDeals);
  }, [filteredDeals]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleDealClick = (deal: DealType) => {
    setSelectedDeal(deal);
    setSearchQuery('');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col h-full">
        <div className="bg-white border-b border-gray-200 shadow-sm">
          <div className="flex justify-between items-center p-4">
            <h2 className="text-2xl font-bold text-gray-800">Deals Pipeline</h2>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search deals..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="px-4 py-2 w-64 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 shadow-sm"
                />
                <svg
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
                {searchResults.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    {searchResults.map(deal => (
                      <div
                        key={deal.id}
                        onClick={() => handleDealClick(deal)}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <p className="font-semibold">{deal.name}</p>
                        <p className="text-sm text-gray-600">{deal.email}</p>
                        <p className="text-sm text-gray-600">{deal.phone}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button
                onClick={() => setIsEditColumnsModalOpen(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
              >
                Edit Columns
              </button>
            </div>
          </div>
        </div>
        <div className="h-1 bg-gradient-to-b from-gray-200 to-transparent"></div>
        <div className="flex-1 overflow-x-auto overflow-y-hidden" ref={scrollContainerRef}>
          <div className="flex h-full p-4 space-x-4">
            {localColumns.sort((a, b) => a.order - b.order).map(column => (
              <Column
                key={column.id}
                column={column}
                onAddDeal={addDeal}
                onMoveDeal={moveDeal}
                onUpdateDeal={updateColumnDeals}
                onDeleteDeal={onDeleteDeal}
                onAddTask={addTask}
                onUpdateTask={updateTask}
                onDeleteTask={deleteTask}
                columns={localColumns} // Add this line
              />
            ))}
          </div>
        </div>
      </div>
      <EditColumnsModal
        isOpen={isEditColumnsModalOpen}
        onClose={() => setIsEditColumnsModalOpen(false)}
        columns={localColumns}
        onUpdateColumns={updateColumns}
      />
      {selectedDeal && (
        <DealModal
          isOpen={!!selectedDeal}
          onClose={() => setSelectedDeal(null)}
          onAddDeal={() => {}} // This won't be used for editing
          onUpdateDeal={(id, updatedDeal) => {
            onUpdateDeal(id, updatedDeal);
            setSelectedDeal(null);
          }}
          onDeleteDeal={onDeleteDeal}
          onAddTask={addTask}
          onUpdateTask={updateTask}
          onDeleteTask={deleteTask}
          initialDeal={selectedDeal}
          columns={localColumns}
          onMoveDeal={moveDeal}
        />
      )}
    </DndProvider>
  );
};

export default KanbanBoard;
