import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import KanbanBoard from './components/KanbanBoard';
import AnalyticsPage from './components/AnalyticsPage';
import { DealType } from './components/Deal';
import { supabase } from './supabase';
import Login from './components/Login';
import UserMenu from './components/UserMenu';
import AccountPage from './components/AccountPage';
import IntegrationPage from './components/IntegrationPage';
import useTracking from './hooks/useTracking';
import LandingPage from './components/LandingPage';

// Create a new component to wrap the main content
const MainContent: React.FC<{
  user: any;
  deals: DealType[];
  columns: any[];
  addDeal: (deal: Omit<DealType, 'id' | 'createdAt'>) => void;
  updateDeal: (id: string, updatedDeal: Partial<DealType>) => void;
  deleteDeal: (id: string) => void;
  updateColumns: (columns: any[]) => void;
  createDefaultKanban: () => void;
  columnsFetched: boolean;
}> = ({ user, deals, columns, addDeal, updateDeal, deleteDeal, updateColumns, createDefaultKanban, columnsFetched }) => {
  const location = useLocation();
  // Update this line to include 'integrations'
  const activeView = location.pathname === '/analytics' ? 'analytics' : location.pathname === '/integrations' ? 'integrations' : 'deals';

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar activeView={activeView} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white p-4 flex justify-end items-center">
          <UserMenu user={user} />
        </header>
        <div className="h-px bg-gray-200 w-full"></div>
        <main className="flex-1 overflow-hidden pt-2">
          {activeView === 'deals' && (
            columnsFetched && columns.length === 0 ? (
              <div className="flex items-center justify-center h-full">
                <button 
                  onClick={createDefaultKanban} 
                  className="bg-blue-500 hover:bg-blue-600 text-white text-xl font-bold py-4 px-8 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Create Default Kanban
                </button>
              </div>
            ) : (
              <KanbanBoard
                deals={deals}
                columns={columns}
                onAddDeal={addDeal}
                onUpdateDeal={updateDeal}
                onDeleteDeal={deleteDeal}
                onUpdateColumns={updateColumns}
              />
            )
          )}
          {activeView === 'analytics' && (
            <AnalyticsPage deals={deals} columns={columns} />
          )}
          {activeView === 'integrations' && (
            <IntegrationPage />
          )}
        </main>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const [deals, setDeals] = useState<DealType[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [columnsFetched, setColumnsFetched] = useState(false);
  useTracking();

  const fetchDeals = useCallback(async () => {
    if (!user) return;
    const { data, error } = await supabase
      .from('deals')
      .select('*')
      .eq('user_id', user.id);
    if (error) {
      console.error('Error fetching deals:', error);
    } else {
      setDeals(data || []);
    }
  }, [user]);

  const fetchColumns = useCallback(async () => {
    if (!user) return;
    const { data, error } = await supabase
      .from('columns')
      .select('*')
      .eq('user_id', user.id)
      .order('order', { ascending: true });
    if (error) {
      console.error('Error fetching columns:', error);
    } else {
      setColumns(data || []);
    }
    setColumnsFetched(true);
  }, [user]);

  useEffect(() => {
    const fetchSession = async () => {
      console.log('Fetching session...');
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Session fetched:', session);
      setUser(session?.user ?? null);
      setIsLoading(false);
      console.log('Loading set to false');
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log('Auth state changed:', event, session);
        setUser(session?.user ?? null);
        setIsLoading(false);
        console.log('Loading set to false after auth change');
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      fetchDeals();
      fetchColumns();
    }
  }, [user, fetchDeals, fetchColumns]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    fetchUser();
  }, []);

  const addDeal = async (deal: Omit<DealType, 'id' | 'createdAt'>) => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const { data, error } = await supabase.from('deals').insert([deal]).select();
    if (error) {
      console.error('Error adding deal:', error);
    } else if (data && data.length > 0) {
      setDeals(prevDeals => [...prevDeals, data[0]]);
    }
  };

  const updateDeal = async (id: string, updatedDealData: Partial<DealType>) => {
    const { data, error } = await supabase
      .from('deals')
      .update(updatedDealData)
      .eq('id', id)
      .select();

    if (error) {
      console.error('Error updating deal:', error);
    } else if (data && data.length > 0) {
      setDeals(prevDeals => prevDeals.map(deal => deal.id === id ? { ...deal, ...data[0] } : deal));
    }
  };

  const deleteDeal = async (id: string) => {
    const { error } = await supabase.from('deals').delete().eq('id', id);
    if (error) {
      console.error('Error deleting deal:', error);
    } else {
      setDeals(prevDeals => prevDeals.filter(deal => deal.id !== id));
    }
  };

  const createDefaultKanban = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const defaultColumns = [
      { title: 'New Leads', order: 0 },
      { title: 'Processing', order: 1 },
      { title: 'No Response', order: 2 },
      { title: 'Interested', order: 3 },
      { title: 'Closed Won', order: 4 },
    ];

    const { data, error } = await supabase
      .from('columns')
      .insert(defaultColumns.map(col => ({ ...col, user_id: user.id })))
      .select();

    if (error) {
      console.error('Error creating default columns:', error);
    } else {
      setColumns(data || []);
    }
  };

  const updateColumns = useCallback(async (updatedColumns: any[]) => {
    setColumns(updatedColumns);
    // If you want to update the columns in the database, you can add that logic here
  }, []);

  console.log('Rendering App, isLoading:', isLoading, 'user:', user);

  return (
    <Router>
      {isLoading ? (
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/deals" />} />
          <Route
            path="/deals"
            element={
              user ? (
                <MainContent
                  user={user}
                  deals={deals}
                  columns={columns}
                  addDeal={addDeal}
                  updateDeal={updateDeal}
                  deleteDeal={deleteDeal}
                  updateColumns={updateColumns}
                  createDefaultKanban={createDefaultKanban}
                  columnsFetched={columnsFetched}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/analytics"
            element={
              user ? (
                <MainContent
                  user={user}
                  deals={deals}
                  columns={columns}
                  addDeal={addDeal}
                  updateDeal={updateDeal}
                  deleteDeal={deleteDeal}
                  updateColumns={updateColumns}
                  createDefaultKanban={createDefaultKanban}
                  columnsFetched={columnsFetched}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/account"
            element={user ? <AccountPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/integrations"
            element={
              user ? (
                <MainContent
                  user={user}
                  deals={deals}
                  columns={columns}
                  addDeal={addDeal}
                  updateDeal={updateDeal}
                  deleteDeal={deleteDeal}
                  updateColumns={updateColumns}
                  createDefaultKanban={createDefaultKanban}
                  columnsFetched={columnsFetched}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
