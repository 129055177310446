import React, { useMemo } from 'react';
import { DealType } from './Deal';

interface AnalyticsPageProps {
  deals: DealType[];
  columns: { id: string; title: string }[];
}

interface AnalyticsItem {
  columnTitle: string;
  quantity: number;
  percentage: number;
}

const AnalyticsPage: React.FC<AnalyticsPageProps> = ({ deals, columns }) => {
  const analyticsItems: AnalyticsItem[] = useMemo(() => {
    const totalDeals = deals.length;
    return columns.map(column => {
      const dealsInColumn = deals.filter(deal => deal.status === column.title).length;
      const percentage = totalDeals > 0 ? (dealsInColumn / totalDeals) * 100 : 0;
      return {
        columnTitle: column.title,
        quantity: dealsInColumn,
        percentage: percentage
      };
    });
  }, [deals, columns]);

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold mb-4 p-6">Deal Analytics</h2>
      <div className="flex-grow overflow-y-auto px-6 pb-6">
        <ol className="list-decimal list-inside space-y-4">
          {analyticsItems.map(({ columnTitle, quantity, percentage }) => (
            <li key={columnTitle} className="bg-white p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold">{columnTitle}</h3>
                <span className="text-sm font-medium">
                  {quantity} deal{quantity !== 1 ? 's' : ''}
                </span>
              </div>
              <div className="flex items-center">
                <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{ width: `${percentage}%` }}
                  ></div>
                </div>
                <span className="text-sm font-medium">{percentage.toFixed(2)}%</span>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default AnalyticsPage;
