import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp } from '../supabase';
import ForgotPassword from './ForgotPassword';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [showSignUpSuggestion, setShowSignUpSuggestion] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setShowSignUpSuggestion(false);

    if (isSignUp) {
      // Sign up process
      const { error: signUpError } = await signUp(email, password);
      if (signUpError) {
        setError(signUpError.message);
      } else {
        // Automatically sign in after successful sign up
        const { error: signInError } = await signIn(email, password);
        if (signInError) {
          setError(signInError.message);
        } else {
          navigate('/deals');
        }
      }
    } else {
      // Regular sign in process
      const { error } = await signIn(email, password);
      if (error) {
        if (error.message.includes('Invalid login credentials')) {
          setError('Invalid email or password. Please try again or sign up for an account.');
        } else if (error.message.includes('Email not confirmed')) {
          setError('Please confirm your email address before logging in.');
        } else if (error.message.includes('User not found')) {
          setError('No account found with this email.');
          setShowSignUpSuggestion(true);
        } else {
          setError(error.message);
        }
      } else {
        navigate('/deals');
      }
    }
  };

  const handleClosePopup = () => {
    setShowSignUpSuggestion(false);
    setIsSignUp(true);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
      {isForgotPassword ? (
        <ForgotPassword onCancel={() => setIsForgotPassword(false)} />
      ) : (
        <div className="px-8 py-6 mt-4 text-left bg-white shadow-2xl rounded-xl w-full max-w-md">
          <h3 className="text-2xl font-bold text-center text-gray-800 mb-4">
            {isSignUp ? 'Sign up for an account' : 'Login to your account'}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-baseline justify-between mt-6">
                <button className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-900 transition-colors duration-300">
                  {isSignUp ? 'Sign Up' : 'Login'}
                </button>
                <div className="flex flex-col items-end">
                  <button
                    type="button"
                    onClick={() => {
                      setIsSignUp(!isSignUp);
                      setError(null);
                      setMessage(null);
                    }}
                    className="px-4 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-100 transition-colors duration-300"
                  >
                    {isSignUp ? 'Already have an account? Login' : 'Need an account? Sign Up'}
                  </button>
                  {!isSignUp && (
                    <button
                      type="button"
                      onClick={() => setIsForgotPassword(true)}
                      className="text-sm text-gray-500 hover:underline mt-2"
                    >
                      Forgot Password?
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
          {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
          {message && <p className="text-green-500 mt-4 text-center">{message}</p>}
          {showSignUpSuggestion && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-8 rounded-lg shadow-2xl max-w-md w-full mx-4">
                <h3 className="text-2xl font-bold text-gray-800 mb-4">Account Not Found</h3>
                <p className="text-lg text-gray-600 mb-6">
                  No account found with this email. Would you like to sign up?
                </p>
                <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
                  <button
                    onClick={() => setShowSignUpSuggestion(false)}
                    className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleClosePopup}
                    className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
