import React, { useState, useEffect } from 'react';
import { DealType } from './Deal';
import { Task } from './Task';
import { XMarkIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import AddDealForm from './AddDealForm';
import TaskList from './TaskList';

interface DealModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddDeal: (deal: Omit<DealType, 'id' | 'status' | 'createdAt'>) => void;
  onUpdateDeal: (id: string, updatedDeal: Partial<DealType>) => void;
  onDeleteDeal?: (id: string) => void;
  onAddTask: (dealId: string, task: Omit<Task, 'id'>) => void;
  onUpdateTask: (dealId: string, taskId: string, updatedTask: Partial<Task>) => void;
  onDeleteTask: (dealId: string, taskId: string) => void;
  initialDeal?: DealType;
  columns: { id: string; title: string }[];
  onMoveDeal: (dealId: string, fromColumnId: string, toColumnId: string) => void;
}

const DealModal: React.FC<DealModalProps> = ({
  isOpen,
  onClose,
  onAddDeal,
  onUpdateDeal,
  onDeleteDeal,
  onAddTask,
  onUpdateTask,
  onDeleteTask,
  initialDeal,
  columns,
  onMoveDeal,
}) => {
  const [dealTasks, setDealTasks] = useState<Task[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedColumnId, setSelectedColumnId] = useState<string | undefined>(
    initialDeal ? columns.find(col => col.title === initialDeal.status)?.id : undefined
  );

  useEffect(() => {
    if (isOpen) {
      if (initialDeal) {
        setDealTasks(initialDeal.tasks || []);
        setSelectedColumnId(columns.find(col => col.title === initialDeal.status)?.id);
      } else {
        setDealTasks([]);
        setSelectedColumnId(undefined);
      }
    }
  }, [isOpen, initialDeal, columns]);

  if (!isOpen) return null;

  const handleSubmit = (deal: Omit<DealType, 'id' | 'status' | 'createdAt'>) => {
    if (initialDeal) {
      const updatedDeal = { ...deal, tasks: dealTasks };
      const newColumnTitle = selectedColumnId ? columns.find(col => col.id === selectedColumnId)?.title : initialDeal.status;
      
      if (newColumnTitle && newColumnTitle !== initialDeal.status) {
        // Update deal with new status and move to new column
        onUpdateDeal(initialDeal.id, { ...updatedDeal, status: newColumnTitle });
        onMoveDeal(initialDeal.id, columns.find(col => col.title === initialDeal.status)!.id, selectedColumnId!);
      } else {
        // Just update the deal without changing the column
        onUpdateDeal(initialDeal.id, updatedDeal);
      }
    } else {
      onAddDeal({ ...deal, tasks: dealTasks });
    }
    setUnsavedChanges(false);
    onClose();
  };

  const handleColumnChange = (columnId: string) => {
    setSelectedColumnId(columnId);
    setUnsavedChanges(true);
  };

  const handleAddTask = (task: Omit<Task, 'id'>) => {
    const newTask = { ...task, id: Date.now().toString() };
    setDealTasks(prevTasks => [...prevTasks, newTask]);
    setUnsavedChanges(true);
  };

  const handleUpdateTask = (taskId: string, updatedTask: Partial<Task>) => {
    setDealTasks(prevTasks => 
      prevTasks.map(task => task.id === taskId ? {...task, ...updatedTask} : task)
    );
    setUnsavedChanges(true);
  };

  const handleDeleteTask = (taskId: string) => {
    setDealTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
    setUnsavedChanges(true);
  };

  const handleSaveTasks = () => {
    if (initialDeal) {
      onUpdateDeal(initialDeal.id, { ...initialDeal, tasks: dealTasks });
    }
    setUnsavedChanges(false);
  };

  const confirmDeleteDeal = () => {
    if (initialDeal && onDeleteDeal) {
      onDeleteDeal(initialDeal.id);
      setShowDeleteConfirmation(false);
      onClose();
    }
  };

  const handleCloseWithSave = () => {
    if (unsavedChanges) {
      handleSaveTasks();
    }
    onClose();
  };

  const handleProfileLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (initialDeal?.profileLink) {
      window.open(initialDeal.profileLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`bg-white rounded-lg ${initialDeal ? 'w-full max-w-7xl h-5/6' : 'w-full max-w-md'} relative`}>
        <button
          onClick={handleCloseWithSave}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Save and close modal"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <div className="p-6 flex h-full">
          <div className={`${initialDeal ? 'w-1/3 pr-6' : 'w-full'}`}>
            <h2 className="text-2xl font-bold mb-4">{initialDeal ? 'Deal' : 'Add New Deal'}</h2>
            <AddDealForm
              onAddDeal={handleSubmit}
              onCancel={onClose}
              initialDeal={initialDeal}
              columns={columns}
              selectedColumnId={selectedColumnId}
              onColumnChange={handleColumnChange}
              renderProfileLinkIcon={(profileLink) => (
                profileLink && (
                  <button
                    onClick={handleProfileLinkClick}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
                    title="View Link"
                  >
                    <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                  </button>
                )
              )}
            />
            {initialDeal && (
              <button
                onClick={() => setShowDeleteConfirmation(true)}
                className="mt-4 border border-red-300 text-red-500 px-4 py-2 rounded-md transition-colors duration-300 hover:bg-red-50 hover:text-red-600 hover:border-red-400 focus:outline-none focus:ring-2 focus:ring-red-200"
              >
                Delete Deal
              </button>
            )}
          </div>
          {initialDeal && (
            <div className="w-2/3 pl-6 border-l">
              <h3 className="text-xl font-bold mb-4">Tasks</h3>
              <div className="h-5/6 overflow-y-auto">
                <TaskList
                  tasks={dealTasks}
                  onAddTask={handleAddTask}
                  onUpdateTask={handleUpdateTask}
                  onDeleteTask={handleDeleteTask}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
            <p className="text-xl font-semibold mb-6 text-center">Are you sure you want to delete this deal?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="bg-gray-100 text-gray-800 px-6 py-2 rounded-md hover:bg-gray-200 transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteDeal}
                className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600 transition-colors duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealModal;