import React, { useState } from 'react';
import { supabase } from '../supabase';

interface ForgotPasswordProps {
  onCancel: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onCancel }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) {
      setError(error.message);
    } else {
      setMessage('Password reset email sent. Check your inbox.');
    }
  };

  return (
    <div className="px-8 py-6 mt-4 text-left bg-white shadow-2xl rounded-xl w-full max-w-md">
      <h3 className="text-2xl font-bold text-center text-gray-800 mb-4">
        Forgot Password
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            required
          />
        </div>
        <div className="flex items-baseline justify-between mt-6">
          <button
            type="submit"
            className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-900 transition-colors duration-300"
          >
            Reset Password
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="text-blue-600 hover:underline"
          >
            Back to Login
          </button>
        </div>
      </form>
      {message && <p className="text-green-500 mt-4 text-center">{message}</p>}
      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
    </div>
  );
};

export default ForgotPassword;
