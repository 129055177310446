import React, { useState, useMemo, useCallback } from 'react';
import { Task } from './Task';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Remove the local ImportedTask interface

// Update type references
interface TaskListProps {
  tasks: Task[];
  onAddTask: (task: Omit<Task, 'id'>) => void;
  onUpdateTask: (taskId: string, updatedTask: Partial<Task>) => void;
  onDeleteTask: (taskId: string) => void;
}

const TaskList: React.FC<TaskListProps> = ({ tasks, onAddTask, onUpdateTask, onDeleteTask }) => {
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskDueDate, setNewTaskDueDate] = useState<Date | null>(null);
  const [completedTaskId, setCompletedTaskId] = useState<string | null>(null);

  const showTemporaryNotification = useCallback((taskId: string) => {
    setCompletedTaskId(taskId);
    setTimeout(() => setCompletedTaskId(null), 3000); // Hide after 3 seconds
  }, []);

  const sortedTasks = useMemo(() => {
    return [...tasks].sort((a, b) => {
      if (a.status === 'Done' && b.status !== 'Done') return 1;
      if (a.status !== 'Done' && b.status === 'Done') return -1;
      return new Date(a.dueDate || '').getTime() - new Date(b.dueDate || '').getTime();
    });
  }, [tasks]);

  const handleAddTask = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newTaskTitle.trim() && newTaskDueDate) {
      const dueDate = new Date(newTaskDueDate);
      dueDate.setHours(23, 59, 59, 999);
      onAddTask({
        title: newTaskTitle.trim(),
        description: newTaskDescription.trim(),
        status: 'Todo',
        dueDate: dueDate.toISOString()
      });
      setNewTaskTitle('');
      setNewTaskDescription('');
      setNewTaskDueDate(null);
    }
  };

  const handleCompleteTask = (taskId: string) => {
    onUpdateTask(taskId, { status: 'Done' });
    showTemporaryNotification(taskId);
  };

  const getTaskStyle = (task: Task) => {
    if (task.status === 'Done') return 'bg-gray-100';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (!task.dueDate) return 'bg-white'; // Handle case where dueDate is undefined

    const dueDate = new Date(task.dueDate);
    dueDate.setHours(0, 0, 0, 0);

    if (dueDate.getTime() === today.getTime()) return 'bg-green-100';
    if (dueDate < today) return 'bg-red-100';
    return 'bg-white';
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'No due date';
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="mt-4">
      <form onSubmit={handleAddTask} className="mb-4 space-y-2">
        <input
          type="text"
          value={newTaskTitle}
          onChange={(e) => setNewTaskTitle(e.target.value)}
          placeholder="Task title"
          className="w-full p-2 border rounded"
          required
        />
        <input
          type="text"
          value={newTaskDescription}
          onChange={(e) => setNewTaskDescription(e.target.value)}
          placeholder="Note (optional)"
          className="w-full p-2 border rounded"
        />
        <DatePicker
          selected={newTaskDueDate}
          onChange={(date: Date | null) => setNewTaskDueDate(date)}
          minDate={new Date()}
          placeholderText="Select due date"
          className="w-full p-2 border rounded"
          required
        />
        <div className="flex justify-start">
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            Add Task
          </button>
        </div>
      </form>
      <ul className="space-y-2">
        {sortedTasks.length > 0 ? (
          sortedTasks.map((task) => (
            <li key={task.id} className={`${getTaskStyle(task)} p-4 rounded-lg relative flex items-center`}>
              {task.status !== 'Done' && (
                <button
                  onClick={() => handleCompleteTask(task.id)}
                  className="mr-4 p-2 rounded-full bg-white border border-gray-300 hover:bg-gray-100 transition-colors flex-shrink-0"
                  title="Mark as done"
                >
                  <CheckIcon className="h-6 w-6 text-gray-500" />
                </button>
              )}
              <div className="flex-grow">
                <h4 className="font-semibold pr-8">{task.title}</h4>
                <p>{task.description}</p>
                <p className={`${task.status === 'Done' ? 'text-green-600' : 'text-yellow-600'}`}>
                  Status: {task.status}
                </p>
                <p>Due: {formatDate(task.dueDate)}</p>
              </div>
              <button
                onClick={() => onDeleteTask(task.id)}
                className="absolute top-1 right-1 text-red-500 hover:text-red-700 p-1"
                title="Delete task"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
              {completedTaskId === task.id && (
                <div className="absolute top-0 left-0 right-0 bg-green-500 text-white px-4 py-2 rounded-t-lg text-sm">
                  Create a new task to stay in touch
                </div>
              )}
            </li>
          ))
        ) : (
          <li>No tasks yet</li>
        )}
      </ul>
    </div>
  );
};

export default TaskList;



