import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
  const [showDemo, setShowDemo] = useState(false);

  const toggleDemo = () => setShowDemo(!showDemo);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white relative overflow-hidden">
      {/* Dot pattern background */}
      <div className="absolute inset-0 z-0 dot-pattern opacity-50"></div>

      {/* Content */}
      <div className="relative z-10">
        <header className="px-4 py-6 md:px-6 lg:px-8">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <svg className="w-10 h-10 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
              </svg>
              <span className="text-3xl font-extrabold text-gray-900">SalesUp</span>
            </div>
            <div>
              <Link to="/login" className="text-blue-600 hover:text-blue-700 font-medium transition duration-300 ease-in-out">Log in</Link>
            </div>
          </div>
        </header>

        <main className="container mx-auto px-4 md:px-6 lg:px-8">
          <div className="text-center max-w-4xl mx-auto py-20 md:py-32">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-extrabold text-gray-900 mb-8 leading-tight">
              Convert Estimates into <span className="text-blue-600">20% more Sales</span>
            </h1>
            <p className="text-xl md:text-2xl text-gray-600 mb-12 leading-relaxed">
              The estimators CRM solution designed to streamline your workflow, boost productivity, and skyrocket your sales.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
              <Link to="/login" className="bg-blue-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
                Get Started for Free
              </Link>
              <button onClick={toggleDemo} className="bg-gray-100 text-gray-800 px-8 py-4 rounded-full text-lg font-semibold hover:bg-gray-200 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
                Watch Demo
              </button>
            </div>
          </div>

          <div className="mt-32 space-y-64">
            {/* Kanban Board Section */}
            <section className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 md:pr-12">
                <h2 className="text-4xl font-bold mb-6 text-blue-600">Visualize Your Sales Pipeline</h2>
                <p className="text-xl text-gray-600 mb-8 leading-relaxed">
                  Our intuitive Kanban board helps you manage your deals effortlessly. Drag and drop to update deal status, and get a clear overview of your sales pipeline at a glance.
                </p>
              </div>
              <div className="md:w-1/2 mt-12 md:mt-0">
                <img src="/kanban-board.png" alt="Kanban Board" className="rounded-2xl shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out" />
              </div>
            </section>

            {/* Tasks Management Section */}
            <section className="flex flex-col md:flex-row-reverse items-center">
              <div className="md:w-1/2 md:pl-12">
                <h2 className="text-4xl font-bold mb-6 text-blue-600">Stay on Top of Your Tasks</h2>
                <p className="text-xl text-gray-600 mb-8 leading-relaxed">
                  Never miss a follow-up with our powerful task management system. Set due dates, add notes, and track progress to ensure every lead gets the attention it deserves.
                </p>
              </div>
              <div className="md:w-1/2 mt-12 md:mt-0">
                <img src="/tasks-management.png" alt="Tasks Management" className="rounded-2xl shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out" />
              </div>
            </section>

            {/* Analytics Section */}
            <section className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 md:pr-12">
                <h2 className="text-4xl font-bold mb-6 text-blue-600">Data-Driven Insights</h2>
                <p className="text-xl text-gray-600 mb-8 leading-relaxed">
                  Make informed decisions with our comprehensive analytics. Track your sales performance, identify bottlenecks, and optimize your sales process for maximum efficiency.
                </p>
              </div>
              <div className="md:w-1/2 mt-12 md:mt-0">
                <img src="/analytics.png" alt="Analytics Dashboard" className="rounded-2xl shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out" />
              </div>
            </section>

            {/* Integrations Section */}
            <section className="flex flex-col md:flex-row-reverse items-center">
              <div className="md:w-1/2 md:pl-12">
                <h2 className="text-4xl font-bold mb-6 text-blue-600">Seamless Integrations</h2>
                <p className="text-xl text-gray-600 mb-8 leading-relaxed">
                  Connect SalesUp with your favorite tools. Our integrations with Jobber, House Call Pro, and Service Titan ensure a smooth workflow across all your business processes.
                </p>
              </div>
              <div className="md:w-1/2 mt-12 md:mt-0">
                <img src="/integrations.png" alt="Integrations" className="rounded-2xl shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out" />
              </div>
            </section>
          </div>
        </main>

        {/* Demo Video Popup */}
        {showDemo && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl">
              <div className="flex justify-between items-center p-4 border-b">
                <h3 className="text-xl font-semibold">Product Demo</h3>
                <button onClick={toggleDemo} className="text-gray-500 hover:text-gray-700">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="p-4">
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    src="https://www.youtube.com/embed/Ma1jDdQhZYw"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                    title="SalesUp Product Demo Video"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}

        <footer className="bg-gray-50 py-16 mt-32">
          <div className="container mx-auto px-4 md:px-6 lg:px-8 text-center text-gray-600">
            <p>&copy; 2024 SalesUp. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
