import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import Deal, { DealType } from './Deal';
import DealModal from './DealModal';
import { Task } from './Task';

interface ColumnProps {
  column: {
    id: string;
    title: string;
    deals: DealType[];
  };
  onAddDeal: (columnId: string, deal: Omit<DealType, 'id' | 'status' | 'createdAt'>) => void;
  onUpdateDeal: (columnId: string, updatedColumn: { deals: DealType[] }) => void;
  onMoveDeal: (dealId: string, fromColumnId: string, toColumnId: string) => void;
  onDeleteDeal: (dealId: string) => void;
  onAddTask: (dealId: string, task: Omit<Task, 'id'>) => void;
  onUpdateTask: (dealId: string, taskId: string, updatedTask: Partial<Task>) => void;
  onDeleteTask: (dealId: string, taskId: string) => void;
  columns: { id: string; title: string }[]; // Add this line
}

const Column: React.FC<ColumnProps> = ({
  column,
  onAddDeal,
  onMoveDeal,
  onUpdateDeal,
  onDeleteDeal,
  onAddTask,
  onUpdateTask,
  onDeleteTask,
  columns, // Add this line
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingDeal, setEditingDeal] = useState<DealType | undefined>(undefined);
  const [newDealAdded, setNewDealAdded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const getTaskPriority = (deal: DealType) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const hasRedTask = deal.tasks?.some(task => {
      if (!task.dueDate) return false;
      const dueDate = new Date(task.dueDate);
      dueDate.setHours(0, 0, 0, 0);
      return task.status !== 'Done' && dueDate < today;
    }) || false;

    const hasGreenTask = deal.tasks?.some(task => {
      if (!task.dueDate) return false;
      const dueDate = new Date(task.dueDate);
      dueDate.setHours(0, 0, 0, 0);
      return task.status !== 'Done' && dueDate.getTime() === today.getTime();
    }) || false;

    if (hasRedTask) return 2; // Highest priority
    if (hasGreenTask) return 1; // Second highest priority
    return 0; // No priority
  };

  const sortedDeals = useMemo(() => {
    const deals = Array.isArray(column.deals) ? column.deals : [];
    return [...deals].sort((a, b) => {
      const priorityA = getTaskPriority(a);
      const priorityB = getTaskPriority(b);
      if (priorityA !== priorityB) {
        return priorityB - priorityA; // Higher priority first
      }
      // If priorities are the same, maintain original order
      return deals.indexOf(a) - deals.indexOf(b);
    });
  }, [column.deals]);

  const [, drop] = useDrop({
    accept: 'DEAL',
    hover: () => {
      setIsDragging(true);
    },
    drop: (item: { id: string, columnId: string }) => {
      if (item.columnId !== column.id) {
        onMoveDeal(item.id, item.columnId, column.id);
      }
      setIsDragging(false);
    },
  });

  const handleAddDeal = (deal: Omit<DealType, 'id' | 'status' | 'createdAt'>) => {
    onAddDeal(column.id, deal);
  };

  const handleEditDeal = (deal: DealType) => {
    setEditingDeal(deal);
    setIsModalOpen(true);
  };

  const handleDeleteDeal = (id: string) => {
    onDeleteDeal(id);
    setIsModalOpen(false);
    setEditingDeal(undefined);
  };

  const columnRef = useRef<HTMLDivElement>(null);
  const prevDealsLengthRef = useRef(column?.deals?.length ?? 0);

  useEffect(() => {
    if (newDealAdded && columnRef.current && !isDragging) {
      columnRef.current.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
      setNewDealAdded(false);
    }
  }, [newDealAdded, isDragging]);

  useEffect(() => {
    if (column?.deals?.length > prevDealsLengthRef.current) {
      setNewDealAdded(true);
    }
    prevDealsLengthRef.current = column?.deals?.length ?? 0;
  }, [column?.deals]);

  // Render null if column is undefined
  if (!column) {
    return null;
  }

  return (
    <div ref={drop} className="bg-gray-100 rounded-lg shadow-md w-64 flex-shrink-0 flex flex-col h-full">
      <div className="p-3">
        <h3 className="text-lg font-semibold text-gray-800">{column.title}</h3>
        <p className="text-sm text-gray-500">{sortedDeals.length} deals</p>
      </div>
      <div ref={columnRef} className="flex-grow overflow-y-auto px-2 py-1">
        {sortedDeals.map((deal) => (
          <div key={deal.id}>
            <Deal deal={deal} columnId={column.id} onEdit={handleEditDeal} />
          </div>
        ))}
      </div>
      <div className="p-2">
        <button
          onClick={() => setIsModalOpen(true)}
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 text-sm"
        >
          + Add Deal
        </button>
      </div>
      <DealModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingDeal(undefined);
        }}
        onAddDeal={handleAddDeal}
        onUpdateDeal={(id, updatedDeal) => {
          const updatedDeals = column.deals.map(deal =>
            deal.id === id ? { ...deal, ...updatedDeal } : deal
          );
          onUpdateDeal(column.id, { deals: updatedDeals });
        }}
        onDeleteDeal={handleDeleteDeal}
        onAddTask={onAddTask}
        onUpdateTask={onUpdateTask}
        onDeleteTask={onDeleteTask}
        initialDeal={editingDeal}
        columns={columns} // Add this line
        onMoveDeal={onMoveDeal} // Add this line
      />
    </div>
  );
};

export default Column;
