import React, { useState } from 'react';
import { DealType } from './Deal';

interface AddDealFormProps {
  onAddDeal: (deal: Omit<DealType, 'id' | 'status' | 'createdAt'>) => void;
  onCancel: () => void;
  initialDeal?: DealType;
  renderProfileLinkIcon?: (profileLink: string) => React.ReactNode;
  columns: { id: string; title: string }[];
  selectedColumnId?: string;
  onColumnChange: (columnId: string) => void;
}

const AddDealForm: React.FC<AddDealFormProps> = ({
  onAddDeal,
  onCancel,
  initialDeal,
  renderProfileLinkIcon,
  columns,
  selectedColumnId,
  onColumnChange,
}) => {
  const [name, setName] = useState(initialDeal?.name || '');
  const [email, setEmail] = useState(initialDeal?.email || '');
  const [phone, setPhone] = useState(initialDeal?.phone || '');
  const [notes, setNotes] = useState(initialDeal?.notes || '');
  const [profileLink, setProfileLink] = useState(initialDeal?.profileLink || '');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAddDeal({
      name,
      email,
      phone,
      notes,
      profileLink,
      tasks: initialDeal?.tasks || []
    });
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow-md">
      <input
        type="text"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <input
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <input
        type="tel"
        name="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="Phone"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <div className="relative">
        <input
          type="url"
          name="profileLink"
          value={profileLink}
          onChange={(e) => setProfileLink(e.target.value)}
          placeholder="Job Link"
          className="w-full p-2 mb-2 border rounded pr-10"
        />
        {profileLink && renderProfileLinkIcon && renderProfileLinkIcon(profileLink)}
      </div>
      <textarea
        name="notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Notes"
        className="w-full p-2 mb-2 border rounded"
        rows={3}
      />
      {initialDeal && (
        <div className="mb-4">
          <label htmlFor="column-select" className="block text-sm font-medium text-gray-700 mb-1">
            Change Column
          </label>
          <select
            id="column-select"
            value={selectedColumnId}
            onChange={(e) => onColumnChange(e.target.value)}
            className="w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            {columns.map((column) => (
              <option key={column.id} value={column.id}>
                {column.title}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          {initialDeal ? 'Save' : 'Add Deal'}
        </button>
      </div>
    </form>
  );
};

export default AddDealForm;
